import { Backdrop, CircularProgress } from "@mui/material";
import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
const AuthLayout = lazy(() => import("../components/shared/AuthLayout.js"));
const LoginLayout = lazy(() => import("../components/shared/LoginLayout.js"));
const ProtectedLayout = lazy(() =>
  import("../components/shared/ProtectedLayout.js")
);
const Login = lazy(() => import("../components/loginComponent/Login.js"));
const App = lazy(() => import("../app/App"));
const NotFoundPage = lazy(() => import("../components/shared/NotFoundPage.js"));

export const WaitLoader = () => {
  return (
    <Backdrop
      open={true}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <Suspense fallback={<WaitLoader />}>
              <AuthLayout />
            </Suspense>
          }>
          <Route
            path='/'
            errorElement={<NotFoundPage />}
            element={
              <Navigate
                to='/App'
                replace
              />
            }
          />
          <Route
            element={
              <Suspense fallback={<WaitLoader />}>
                <LoginLayout />
              </Suspense>
            }>
            <Route
              errorElement={<NotFoundPage />}
              path='/login'
              element={<Login />}
            />
          </Route>
          <Route
            element={
              <Suspense fallback={<WaitLoader />}>
                <ProtectedLayout />
              </Suspense>
            }>
            <Route
              errorElement={<NotFoundPage />}
              path='/App'
              element={<App />}
            />
          </Route>
        </Route>
        <Route
          path='*'
          element={
            <Navigate
              to='/App'
              replace
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
