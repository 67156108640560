import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { AppProvider } from "./app/AppContext.js";
import { msalConfig } from "./auth.config.js";
import AppRoutes, { WaitLoader } from "./configs/routes.config";

const Root = () => {
  const [msalInstance, setMsalInstance] = useState(null);

  useEffect(() => {
    const msalInstance = new PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();

    if (!msalInstance.getActiveAccount() && accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
      if (
        [
          EventType.LOGIN_SUCCESS,
          EventType.ACQUIRE_TOKEN_SUCCESS,
          EventType.SSO_SILENT_SUCCESS,
        ].includes(event.eventType) &&
        event.payload.account
      ) {
        msalInstance.setActiveAccount(event.payload.account);
      }
    });

    setMsalInstance(msalInstance);
  }, []);

  if (!msalInstance) {
    return <WaitLoader />;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <AppRoutes />
    </MsalProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppProvider>
    <Root />
  </AppProvider>
);
