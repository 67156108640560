import React, { createContext, useContext } from "react";

const AppContext = createContext();

export const AppProvider = ({ children, query, data }) => {
  return (
    <AppContext.Provider value={{ query, data }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
